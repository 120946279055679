import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import SliderContent from '../../components/GSTTemp/SliderContent';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function SocietyRegistration() {
  /* Slider */
  // const societySlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(207, 249, 216) 28.54%, rgb(243, 238, 160) 104.43%)`,
  //     header: `Online Society Registration`,
  //     content: `Secure Society Registration.`,
  //     image: "/imgs/business/sliders/pvt-slider.png",
  //     alt_tag: "Online Society Registration Near Banglore",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Society Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `24999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const societyAboutData = {
    header: `Online Society Registration`,
    sub_title: `Best NGO Society Registration service in Hosur.`,
    content_paragraph: [
      `Society is an association or grouping of various people who have come to terms and joined forces in order to 
      manage and pursue a single goal. With the help of TODAYFILINGS Professional Experts, pursue the vision for Society 
      Registration Service Provider Online in India and realize your multi-million dollar dream of social 
      entrepreneurship!`,

      // `In India, the Society Registration Act 1860 governs and regulates the procedures for registering a society.
      // `,
    ],
  };

  /* Scroll Nav Data */
  const societyScrollId = [
    {
      id: `#types`,
      heading: `Types`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#benefitss`,
      heading: `Advantages`,
    },
    {
      id: `#documents`,
      heading: `Documents`,
    },
  ];

  /* Img Content Component Data */
  const societyIcData = {
    id: 'types',
    mt_div: 'mt-5',
    mt_img: '',
    header: 'Types Of Trusts',
    image: '/imgs/registration/ngo/society.png',
    alt_tag: 'non-government organization',
    points: [
      {
        head: `What is trust?`,
        content: `According to the Indian Trust Act of 1882, a trust is a legal entity in which the owner, 
        or trust, decides to assign the authority and control over his property or asset to a third party,
         called the trustee, so that the beneficiary, or third party, might benefit from it.`,
        icon: true,
      },
      {
        head: '',
        content: `Such an asset or property is transferred to the trustee by the trust registry along with 
        a declaration that the trustee shall own it on behalf of the trust's beneficiaries.`,
        icon: true,
      },
      {
        head: 'Categories of trust',
        content: ``,
        icon: false,
      },
      {
        head: '',
        content: `Public Trust`,
        icon: true,
      },
      {
        head: '',
        content: `Private Trust`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const societyCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'NGO Society Registration Procedures',
    body: [
      {
        head: `Registration of an NGO Society`,
        points: ``,
        icon: false,
      },
      {
        head: `Step 1:`,
        points: `Fill up the NGO application form. 
        in this phase, you must complete the short online form with all the required document`,
        icon: true,
      },
      {
        head: `Step 2:`,
        points: `Collection of information you must drought the
         society registration application using the given information.`,
        icon: true,
      },
      {
        head: `Step 3:`,
        points: `Drafting memoranda the memoranda of the association is 
        drafted in this step in accordance with the terms.`,
        icon: true,
      },
      {
        head: 'Step 4',
        points: `Creating the rules and guidelines based on the regulations
         you must setup for your NGO, which will be listed in your voluntree registration in your NGO.`,
        icon: true,
      },
      {
        head: 'Step 5:',
        points: `The NGO Society registration has been finished. 
        the granted certificate will be delivered by courier after the NGO society has been registered.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const societyCiData = {
    id: 'documents',
    background: 'main-content',
    mt_div: 'mb-4',
    header: 'Required Documents For NGO Registration',
    paragraph: ``,
    points: [
      {
        head: 'Society Registration Documents',
        content: `The following documents are required for NGO trust registration under "Society Registration Act, 1860."`,
        icon: false,
      },
      {
        content: `Name of the society.`,
        icon: true,
      },
      {
        content: `Proof of the registered office's address.`,
        icon: true,
      },
      {
        content: `Identity documentation for each firm employee.`,
        icon: true,
      },
      {
        content: `The MOA and Bylaws in two copies, both in conformity with the specification for society registration.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme-doc.png',
    alt_tag: 'Intellectual property dispute',
  };

  /* ImgSlider Data */
  const societyImages = [
    '/imgs/assited/cfo-management.png',
    '/imgs/registration/ngo/trust.png',
    '/imgs/assited/acc-rec-monitoring.png',
  ];
  /*  Slider Content Component Data */
  const societySCData = {
    id: 'benefitss',
    background: [],
    mt_div: 'mt-3',
    header: 'Advantages Of Trust Registration',
    content: [
      {
        head: '',
        points: `Each certified society receives legal 
        recognition and Registered society can legally open a new account in its name.`,
        icon: true,
      },
      {
        head: '',
        points: `According to income tax act, you can obtain commission and regard.`,
        icon: true,
      },
      {
        head: '',
        points: `Certified societies receive the legal standing required for the 
         satutory enthronement of society's wealth.`,
        icon: true,
      },
    ],
    images: societyImages,
  };

  var FAQ_data = [
    {
      header: " Who is necessary to sign the society's memorandum?",
      body: [
        {
          content: `Individuals, partnership firms, corporations, foreigners, and
          other certified organizations must sign the society's memorandum.`,
          icon: false,
        },
      ],
    },

    {
      header: 'Do the members of society share in the profits?',
      body: [
        {
          content: `No, members of the society are not ennobled to use the
          organization's profit in any way.`,
          icon: false,
        },
      ],
    },
    {
      header: "What exactly is a society's General Body?",
      body: [
        {
          content: `The General Body of a society is made up of all the members who have
          signed the organization's statement.`,
          icon: false,
        },
      ],
    },
    {
      header: 'What kind of gathering is necessary for a society meeting?',
      body: [
        {
          content: `In order for any significant decisions and conference procedures to
          be valid, a quorum, or sufficient number of members, must be
          present.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can a government worker have the position of trustee?',
      body: [
        {
          content: `Yes, a government worker is eligible to serve as a trustee for a
          trust.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What is a society registration certificate? ',
      body: [
        {
          content: `The Societies Registration Act refers to legislation under which a society is legalized & formed by MOA.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Can family members form society?',
      body: [
        {
          content: `'Societies Act, 1860' defies the concept of society incorporation by the family members.`,
          icon: false,
        },
      ],
    },
    {
      header: ' How can a society sell its property? ',
      body: [
        {
          content: `A society empowers its founding members to sell the property of a society only if the seller 
          pays standard fees & address the liabilities of the society. The transfer fee, entry fee or membership 
          fee are common expenses that a seller needs to address to make the property's transfer successful.`,
          icon: false,
        },
      ],
    },
    {
      header:
        ' How many members are required to ensure the winding up of the society? ',
      body: [
        {
          content: `The consent of three-fifths of the members of the society is enough to initiate the winding-up 
          procedure of the society.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What are the standard inclusions of MOA for society? ',
      body: [
        {
          content: `MOA of registered society entails the signature of the founding members along 
          with the seal of the concerned authorities.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Society Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={societySlider} /> */}
          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={societyAboutData} />

          <Counter />

          <ScrollNav scroll_data={societyScrollId} />

          <ImgContent item={societyIcData} />

          <ContentForm CFSection_data={societyCfData} />

          <SliderContent ScSection_data={societySCData} />

          <ContentImg CISection_data={societyCiData} />

          <Cta />

          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
